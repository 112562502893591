<template>
  <div class="px-main --dark py-9 px-16">
    <div class="d-flex justify-space-between my-auto">
      <h1>ブロック</h1>
      <div class="d-flex justify-end">
        <v-btn
          color="error"
          class="btn-close mr-4"
          to="/reservation/calendar-block"
        >
          {{ $t("buttons.close") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="createBooking()"
           :disabled="!checkPerUser"
        >
          ブロック作成
        </v-btn>
      </div>
    </div>
    <div class="mt-6 bg-white">
      <!-- <v-card class="px-main w-50"> -->
      <v-form ref="form">
        <v-row  style="min-width: 990px">
        <v-col :cols="6" class="mt-8" style="min-width: 770px">
          <v-card
            class="ml-8 mx-15 px-10 py-5"
            color="var(--bg__secondary)"
          >
          <v-btn
            color="primary"
            @click="backSetCalendar()"
          >
            カレンダーから入力
          </v-btn>
          <v-row class="mt-4">
            <v-col cols="6">
              <h6>施設を選択</h6>
            </v-col>
            <v-col cols="6">
              <h6>部屋タイプ</h6>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="6 my-auto">
              <h5>{{facility}}</h5>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="getRoomTypeBlock"
                item-value="id"
                item-text="name"
                v-model="roomTypeId"
                disabled
              ></v-select>
            </v-col>
          </v-row>
            <v-row>
              <v-col cols="6">
                <h6 style="color: #C92D2D !important">部屋</h6>
                <v-select
                  :rules="rules.required"
                  :items="getRoomListBooking"
                  v-model="blockRoomId"
                  item-value="id"
                  item-text="name"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="checkbox"
                  label="部屋割を固定する"
                  disabled
                ></v-checkbox>
              </v-col>
            </v-row>
          <v-row  class="mt-3 ml-4">
            <v-col cols="4">
              <div class="mt-2">
                <h6 class="text-small">{{ $t("common.checkIn") }}</h6>
              </div>
              <div>
                <v-icon class="ml-n8 mr-1">mdi-calendar-month</v-icon>
                  <v-menu
                    v-model="menuCheckInDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="bg--btn-white ml-1 btn-custom-gray"
                      >{{formatDate(checkInDate)}}</v-btn>
                    </template>
                    <v-date-picker
                      :locale="$i18n.locale"
                      v-model="checkInDate"
                      @input="menuCheckInDate = false"
                      :max="checkOutDate"
                      class="date-picker"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="1" class="d-flex align-end mb-1">〜</v-col>
              <v-col cols="4">
                <div class="mt-2">
                  <h6 class="text-small">{{ $t("common.checkOut") }}</h6>
                </div>
                <div>
                  <v-icon class="ml-n8 mr-1">mdi-calendar-month</v-icon>
                  <v-menu
                    v-model="menuCheckOutDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="bg--btn-white ml-1 btn-custom-gray"
                      >{{formatDate(checkOutDate)}}</v-btn>
                    </template>
                    <v-date-picker
                      :locale="$i18n.locale"
                      v-model="checkOutDate"
                      @input="menuCheckOutDate = false"
                      :min="checkInDate"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="3" class="d-flex align-end pt-0">
                <h4 class="text-size-normal">
                 {{night}} {{ $t("contractDetail.night") }}</h4>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="2" class="mt-8">
          <h6>予約バーに表示させる備考内容</h6>
          <v-text-field class="line"
            @change="getBookingBarNote($event)"
            v-model="bookingBarNote"
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
        </v-form>
      <!-- </v-card> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import { formatDate } from '@/constants/functions'
// import { calculateDays } from '@/utils/dateUtil.js'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import gql from 'graphql-tag'
import { isoDate } from '@/utils/dateUtil'

export default {
  name: 'BookingBlock',
  data () {
    return {
      roomTypeId: parseInt(this.$route.query.roomTypeId), // select at no.50, show at left side
      roomTypeName: parseInt(this.$route.query.roomTypeName), // select at no.50, show at left side
      facility: this.$route.query.facility, // select at no.50, show at left side
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      checkbox: true,
      checkInDate: this.$route.query.checkInDate,
      menuCheckInDate: false,
      menuCheckOutDate: false,
      numDay: 0,
      blockRoomId: null,
      bookingBarNote: '',
      bookingTypeId: 11,
      inventoryTypeId: null,
      days: [{
        inventoryTypeId: 1
      }],

      rules: {
        required: [v => !!v || '必須項目です。']
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler () {
        const getDataSelect = JSON.parse(localStorage.getItem('selectedReservationRooms'))
        if (getDataSelect) {
          var result = Object.keys(getDataSelect).map((key) => [key, getDataSelect[key]])
          const days = []
          for (let i = 0; i < result.length; i++) {
            days.push({ inventoryTypeId: result[i][1].inventoryTypeId })
          }
          this.days = days
        }
      }
    },
    checkInDate (newValue, oldValue) {
      const days = moment(newValue)
        .diff(oldValue, 'days')
      if (days > 0) {
        // we must remove the first days
        this.days = this.days.slice(days)
        if (this.days.length === 0) {
          // keep the minimum length
          this.days = [{
            inventoryTypeId: 1
          }]
        }
      } else if (days < 0) {
        this.days = [...Array(-days)].map(() => ({
          inventoryTypeId: 1
        })).concat(this.days)
      }
    }
  },
  mounted () {
    this.roomTypeListBookingBlock()
    this.roomListBookingBlock()
    this.getFacilityList()
  },
  computed: {
    ...mapGetters(['getRoomTypeBlock', 'getRoomListBooking']),
    night () {
      return this.days.length
    },
    checkOutDate: {
      get () {
        return moment(this.checkInDate)
          .add(this.night, 'days')
          .format('YYYY-MM-DD')
      },
      set (v) {
        const days = -moment(this.checkInDate)
          .diff(v, 'days')
        const newDays = this.days.concat([...Array(days)].map(() => {
          return {
            inventoryTypeId: 1
          }
        })).slice(0, days)

        this.days = newDays
      }
    }
  },
  methods: {
    ...mapActions(['roomTypeListBookingBlock', 'roomListBookingBlock', 'createBookingV4BookingBlock']),
    ...mapMutations(['setStatusCalendar', 'setRoomTypeBlock', 'setRoomListBooking', 'setAlertError']),

    async getFacilityList () {
      await this.$apollo.query({
        query: gql`${FACILITY_LIST}`
      }).then((data) => {
        const itemFac = data.data.facilityList.find(item => item.id === parseInt(this.$route.query.facilityID))
        if (itemFac) {
          this.facility = itemFac.name
        }
      }).catch((error) => {
        console.error(error)
      })
    },

    backSetCalendar () {
      this.$router.push('/reservation/calendar-block?type=block&idSelectedFacility=' + this.$route.query.facilityID)
      this.setStatusCalendar(true)
    },

    formatDate,
    getBookingBarNote (event) {
      this.bookingBarNote = event
    },
    createBooking () {
      if (this.$refs.form.validate()) {
        const bookingBlockData = {
          roomTypeId: parseInt(this.roomTypeId),
          checkInDate: this.checkInDate,
          days: this.days,
          bookingTypeId: this.bookingTypeId,
          blockRoomId: this.blockRoomId,
          bookingBarNote: this.bookingBarNote
        }
        const finalize = () => this.createBookingV4BookingBlock(bookingBlockData)

        if (this.checkInDate < isoDate(new Date())) {
          // if checkInDate is in the past, show confirm dialog TO2020-937
          this.$confirm({
            message: '過去の日付で予約を作成します。よろしいですか？',
            ok: finalize,
          })
        } else {
          finalize()
        }
      } else {
        this.setAlertError(this.$t('messages.newCreationFailed'))
      }
    },
    handleClose () {
      window.close()
    }
  }

}
</script>

<style scoped lang="scss">
  .w-50{
    width: 50%;
  }
  .row + .row{
    margin-top: -30px !important;
  }
  .col-4 {
    max-width: 30% !important;
  }
  .col-1 {
    margin-left: -35px !important;
  }
  .col-2{
    margin-left: 50px !important;
  }
  .col-3{
    margin-left: -20px !important;
  }
  h1{
    font-size: 20px !important;
  }
  h6{
    font-size: 10px !important;
    font-weight: 600;
    color: #000 !important;
  }
  h5{
    font-size: 14px !important;
    color: #444444 !important;
    font-weight: 500;
  }
  h4{
    font-size: 16px !important;
    color: #000;
    font-weight: 500;
  }
  .text-red{
    margin-top: -10px !important;
    .v-icon__svg{
      color: #C92D2D !important;
    }
  }
  .mdi-calendar-month{
    color: #333333 !important;
  }
  .bg-white{
    background: #FFFFFF;
    height: 450px;
  }
  ::v-deep{
    .v-btn__content{
      font-size: 10px !important;
    }
    .v-select__selection--comma{
      font-size: 14px !important;
      color: #444444 !important;
      font-weight: 500;
    }
    .text-red{
      .v-icon__svg{
       color: #C92D2D !important;
      }
      .v-input__slot{
        color: #C92D2D !important;
        &:before{
          border-color: #C92D2D !important;
        }
      }
    }
    .line{
      .v-input__slot{
        &:before{
          border-color: #888888 !important;
          // border : 1px solid
        }
      }
    }
    .theme--light.v-label{
      color: #AAAAAA !important;
      font-size: 12px !important;
    }
    .v-application .primary--text{
      color: #AAAAAA !important;
    }
    .v-input--checkbox {
      .primary--text{
        color: #AAAAAA !important;
      }
    }
  }
</style>
